.canvas-container{
  height: 80vw !important;
}

.canvas-tools{
  background-color: #002333;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}


.border{
  border: 1px solid #DEEFE7;
  height: min-content;
  padding: .5rem;
  border-radius: 14px;
}

.border input{
  width: 100% !important;
}

form{
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: #FEEFFE;
  align-items: center;
  gap: 10px;
  border-radius: 14px;
  text-align: center;
  z-index: 500;
}

form input{
  width: 10rem !important;
}

.button-row button{
  margin: 20px;
}

.formButton{
  margin-top: 2rem !important;
  width: 30%;
  color: #FEEFFE !important;
  background-color: #159A9C !important;
}



@media (min-width: 1280px){
  form {    
    width: 30vw;
  }
  .canvas-container{
    height: 30vw !important;
  }

}

@media (max-width: 1280px){
  form {
    width: 60vw;
  }

  .canvas-container{
    height: 60vw !important;
  }

}

@media (max-width: 540px){
  form {
    
    width: 80vw;
  }

  .canvas-tools{
    flex-direction: column;
  }

  .canvas-container{
    height: 80vw !important;
  }

}


.modalContent button{
  margin: 5%;
}