.container{
  padding: 2% 0;
  padding-bottom: 10%;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin: 2rem auto;
  align-items: center;
  gap: 3rem;
  color: #DEEFE7;
  width: 30rem;
  animation: slide-down 300ms ease-out forwards;
}

.container img {
  width: 30rem;
  height: 30rem;
  border-radius: 10px;
  color: #000000;
  background-color: #DEEFE7;
}

@media (max-width: 500px) {
  .container img {
    width: 18rem;
    height: 18rem;
  }
  .container{
    width: 18rem;
  }
}


@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}