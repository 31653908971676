form{
  display: flex;
  width: fit-content;
  flex-direction: column;
  padding: 1rem;
  background-color: #DEEFE7;
  align-items: center;
  gap: 10px;
  border-radius: 14px;
  text-align: center;
}

form input{
  width: 10rem !important;
}

.button-row button{
  margin: 20px;
}

.imageBox{
  padding: 1rem;
  width: min-content;
  border-radius: 14px;
  border: 1px solid #DEEFE7;
}

@media (min-width: 1280px){
  img{
    width: 40vw;
    height: 40vw;
  }
}


@media (max-width: 820px){
  img{
    width: 80vw;
    height: 80vw;
  }
}

@media (max-height: 400px){
  img{
    width: 80vh;
    height: 80vh;
  }
}


@media (min-width: 1280px){
  form {    
    width: 30vw;
  }

}

@media (max-width: 1280px){
  form {
    width: 60vw;
  }

}

@media (max-width: 540px){
  form {
    
    width: 80vw;
  }

}


.formButton{
  margin-top: 2rem !important;
  width: 30%;
  color: #FEEFFE !important;
  background-color: #159A9C !important;
}