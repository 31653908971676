.header {
  width: 100%;
  box-sizing: border-box;
  height: 5rem;
  padding: 0 10%;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  /* gap: 5rem; */
  background-color: #002333;
  position: sticky;
  top: 0;
  border-bottom: #B4BEC9 .5px solid;
  z-index: 10;
}

.header h1, .header h2 {
  
  position: relative;
  width: max-content;
}

.header h1{
  color: #DEEFE7;
  margin-right: auto;
}

.header h2{
  color: rgba(222, 239, 231, .6);
  margin-left: 6%;
}

.header nav{
  margin-left: auto;
}

.header ul {
  position: relative;
  list-style: none;
  margin-left: auto;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 3rem;
}

.header li {
  min-width: fit-content;
}

.mobile-signin{
  display: none;
}

@media (max-width: 575px) {
  .header{
    gap: 1rem;
    height: 4rem;
  }
  .header button{
    font-size: 10px;
  }
  .header ul {
    gap: 5px;
  }
  .header h1{
    font-size: 1.5rem;
    margin: auto;
  }
  .header button {
    font-size: 12px ;
  }

  .header nav{
    display: none;
  }

  .mobile-signin{
    display: inline;
    position: absolute;
    width: max-content;
    margin-left: 65%;
  }
}