.post-items{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  /* background-color: black; */
}

/* h2{
  padding: 0;
  margin: 1% 0;
  width: 100%;
 
} */

.post-title{
  font-size: 1.5em;
  font-weight: bold;
}

.post-info{
  width: max-content;
  display: flex;
  text-align: left;
  flex-direction: column;
  color: rgba(222, 239, 231, .6);
}
.post-info h4{
  margin: 5% 0;
}

.post-buttons{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: -.5rem;
}

.post-buttons button{
  padding: 0;
  align-self: end;
}

.labeled-button{
  display: flex;
  text-align: center;
  flex-direction: column;
  color: rgba(222, 239, 231, .6);
}


@media (max-width: 575px) {
  .post-title{
    font-size: 1.2rem;
  }
}