
.modal {  
  position: fixed;
  overflow: scroll;
  width: 100vw;
  height: 120vh;
  background-color: rgba(0, 0, 0, 0.75);
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 100;
  animation: slide-down 300ms ease-out forwards;
}

.content{
  margin: auto;
  margin-top: 5%;
  width: max-content;
  z-index: 20;
}

@media (max-width: 414px){
  .content{
    margin-top: 25%;
  }
}


@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}